import React, {Fragment} from 'react';
import PageHeader from '../components/PageHeader/index'
import Header from '../components/Header'
import Slider from '../components/Slider/home-two'
import About from '../components/About/home-two'
import Services from '../components/Services'
import Features from '../components/Features'
import Testimonial from "../components/Testimonials/home-two";
import Team from "../components/Team/home-two";
import Blog from "../components/Blog";
import BrandLogo from "../components/BrandLogo";
import Funfact from "../components/Funfact";
import CallToAction from "../components/CallToAction";
import Footer from "../components/Footer";
import LoginRegister from "../components/LoginRegister";
import MobileMenu from "../components/MobileMenu";
import ContactPage from "../templates/Contact-ayudamos";
import {ToastProvider} from 'react-toast-notifications'


const Ayudamos = () => {
    


    return (
        <ToastProvider placement="bottom-left" autoDismiss='true'>
        <Fragment>
            <Header/>
            <PageHeader
                bgImg={require('../assets/img/ayudamos-bueno.jpg')}
                title="AYUDAMOS"
                content=""
            />
            <Blog/>
            <ContactPage/>
            <CallToAction/>
            <Footer/>
            <LoginRegister/>
            <MobileMenu/>
        </Fragment>
        </ToastProvider>
    );
};

export default Ayudamos;