import React, {useState, useEffect} from 'react';
import FormInput from "../../components/UI/Input";
import axios from 'axios'
import { useToasts } from 'react-toast-notifications'

const From = () => {
    const { addToast } = useToasts()
const [inputs, setInputs] = useState({})

const [checkboxComunidad, setCheckboxComunidad] = useState(true)
const [checkboxInstitucion, setCheckboxInstitucion] = useState(true)
const [checkboxVoluntario, setCheckboxVoluntario] = useState(true)




    const onSubmit = (e) => {
        e.preventDefault()

        axios.post("/.netlify/functions/sendgrid-ayudamos", {
            inputs
        })
        .then((res) =>  addToast('Tu correo ha sido enviado exitosamente.', { appearance: 'success' }))
        .catch(e => {
            console.log(e)})
    }
    return (
        <div className="contact-form-wrap">
            <form id="contact-form" onSubmit={onSubmit}>
                <div className="row">
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'text'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            }}
                            name={'name'}
                            placeholder={'Nombre Completo'}
                        />
                    </div>

                    
                    <div className="col-md-6">
                        <FormInput
                            tag={'input'}
                            type={'email'}
                            onChange={(e) => {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'email_address'}
                            placeholder={'Correo Electrónico'}
                        />
                    </div>
                    <div className="col-md-4 flex" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label> Soy comunidad
                    <FormInput
                    
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxComunidad) {
        setCheckboxComunidad(true);
        setInputs({...inputs, [event.target.name]: checkboxComunidad})

    } else {
        setCheckboxComunidad(false);
        setInputs({...inputs, [event.target.name]: checkboxComunidad})
    }

}}
                            name={'checkbox_comunidad'}
                            
                        />
                        </label>
                    </div>

                    <div className="col-md-4" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label>Soy institución
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxInstitucion) {
        setCheckboxInstitucion(true);
        setInputs({...inputs, [event.target.name]: checkboxInstitucion})

    } else {
        setCheckboxInstitucion(false);
        setInputs({...inputs, [event.target.name]: checkboxInstitucion})
    }

}}
                            name={'checkbox_institucion'}
                        />
                        </label>
                    </div>

                    <div className="col-md-4" style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center'}}>
                    <label>Soy voluntario
                    <FormInput
                            tag={'checkbox'}
                            type={'checkbox'}
                            onChange={(event) => {
    if (!checkboxVoluntario) {
        setCheckboxVoluntario(true);
        setInputs({...inputs, [event.target.name]: checkboxVoluntario})
        console.log(inputs)

    } else {
        setCheckboxVoluntario(false);
        setInputs({...inputs, [event.target.name]: checkboxVoluntario})
        console.log(inputs)
    }

}}
                            name={'checkbox_voluntario'}
                        />
                        </label>
                    </div>


                    <div className="col-12">
                        <FormInput
                            tag={'textarea'}

                            onChange = {e =>  {
                                e.persist();
                                setInputs(inputs => ({...inputs, [e.target.name]: e.target.value}));
                                console.log(inputs)
                            } }
                            name={'message'}
                            placeholder={'Mensaje'}
                        />

                        <FormInput
                            tag={'button'}
                            classes={'btn-outline'}
                            style={{color:'#288d36'}}
                          
                        />

                        <div className="form-message"/>
                    </div>
                </div>
            </form>
        </div>
    );
};

export default From;